import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import CountrySelector from "@main/modules/bloonde/location/countries/components/CountrySelector";
import BeelditTypeSelector from "@beeldit/core/components/BeelditTypeSelector";
import { Invoice } from "../../invoices/models/Invoice";
import InvoiceSelector from "../../invoices/components/InvoiceSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function MovementForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    concept: t("concept"),
    origin_type: t("origin_type"),
    type: t("type"),
    date: t("date"),
    amount_without_tax: t("amount_without_tax"),
    tax: t("tax"),
    amount: t("amount"),
    fee: t("fee"),
    external_id: t("external_id"),
    external_data: t("external_data"),
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'movement_id', 'task', 'date', 'report_id'],
    properties: {
      concept: {
        type: "string",
        title: translations.concept,
      },
      origin_type: {
        type: "number",
        title: translations.origin_type,
      },
      type: {
        type: "number",
        title: translations.type,
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
      },
      amount_without_tax: {
        type: "number",
        title: translations.amount_without_tax,
      },
      tax: {
        type: "number",
        title: translations.tax,
      },
      amount: {
        type: "number",
        title: translations.amount,
      },
      fee: {
        type: "number",
        title: translations.fee,
      },
      external_id: {
        type: "string",
        title: translations.external_id,
      },
      external_data: {
        type: ["string", "null"],
        title: translations.external_data,
        format: "textarea",
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    origin_type: {
      "ui:widget": "type-selector",
      "ui:options": {
        configPath: "types.movements.origin_type",
      },
    },
    type: {
      "ui:widget": "type-selector",
      "ui:options": {
        configPath: "types.movements.type",
      },      
    }
  };

  const customWidgets = {
    ...widgets,
    ...{
      "type-selector": BeelditTypeSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default MovementForm;
