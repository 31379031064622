import useExportOrigin from "./useExportOrigin";

function useExportOriginAction(filters: any = {}): any {
  const toExport = useExportOrigin();
  const exportOriginFunction = () => toExport();

  return {
    exportOriginFunction,
  };
}

export default useExportOriginAction;
